<template>
  <div >
    <div class="curContainer">
            <div  width="100%">
    <el-row type="flex" justify="end">
            <!-- <el-button type="primary" size="small" icon="el-icon-download" @click.stop="onExportClick()">添加</el-button> -->
        <!-- <el-button type="primary" @click="onItemNew()" style="margin-right:10px;">新建</el-button> -->
    </el-row>
    <el-form inline :model="productSearchForm" label-width="140px" style="margin-top: 30px;">
      <!-- <el-form-item label="录入日期">
        <el-date-picker
          v-model="productSearchForm.dateArray"
          type="daterange"
          range-separator="至"
          value-format="yyyy-M-d"
          start-placeholder="年/月/日"
          end-placeholder="年/月/日">
        </el-date-picker>
	    </el-form-item> -->
      <el-form-item label="选择站点">
        <el-cascader
            :options="areaStationList"
            :props="props"
            :show-all-levels="false"    
            v-model="productSearchForm.stationArray"
            collapse-tags
            @change="onStationXmlChange(stationArray=$event)"
            clearable></el-cascader>
      </el-form-item>
      <el-form-item label="媒体形式"  style="margin-left:30px;">
                            <el-select v-model="productSearchForm.typeArray" multiple clearable  placeholder="请选择媒体形式">
                                <el-option
                                    v-for="item in dxTypeList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input v-model="productSearchForm.key" autocomplete="off"></el-input>
      </el-form-item>
      <el-button @click="onProductSearch" type="primary">搜索</el-button>
    </el-form>

    <!-- <common-table :data="moniDataList"> -->
      <div v-if="selectedMediaList.length>0" style="display:flex;justify-content:flex-end;">
                <el-button type="primary"  round @click="viewSetDateVisible=true" >设置到期日期</el-button>
              </div>
    <common-table  ref="tableObj" :data="Media_DxList" border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
			@selection-change="handleSelectionChange"
      style="width: 100%;margin-top: 10px;" :row-key="getRowKey" stripe>
      <el-table-column type="selection" reserve-selection width="55"></el-table-column>
      <el-table-column prop="ydarea" align="center" label="大区" ></el-table-column>
      <el-table-column prop="province" align="center" label="省" ></el-table-column>
      <el-table-column prop="city" align="center" label="市" ></el-table-column>
      <el-table-column prop="station" align="center" label="车站" >
        <template v-slot="scope">
            <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
        </template>
      </el-table-column>
      <el-table-column prop="position" align="center" label="位置" ></el-table-column>
     <el-table-column prop="sub1Name" align="center" label="媒体形式1" ></el-table-column>
     <el-table-column prop="sub2Name" align="center" label="媒体形式2" ></el-table-column>
     <el-table-column prop="address" align="center" label="媒体位置" ></el-table-column>
     <el-table-column prop="mediaCode" align="center" label="媒体编号" ></el-table-column>
     <el-table-column prop="count" align="center" label="数量（块）" ></el-table-column>
     <el-table-column prop="adSize" align="center" label="媒体尺寸" ></el-table-column>
     <el-table-column prop="adArea" align="center" label="媒体面积" ></el-table-column>
     <el-table-column prop="expireDate" align="center" label="到期日期" ></el-table-column>
     <!-- <el-table-column prop="zhandiandengji" align="center" label="刊例价(万元/月/组)" ></el-table-column> -->
      <!-- <el-table-column  align="center" label="操作" >
        <template slot-scope="scope">
          <el-button type="primary" size="small"
            @click.native.prevent="onItemEditClick(scope.row)"
          >修改</el-button>
           <el-button type="danger" size="small"
            @click.native.prevent="onItemDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column> -->
    <!-- </common-table> -->
    </common-table>
    <!-- <edit-brand-dialog :visible.sync="addNewDialogVisible" :current-item="{}"/> -->
    <!-- <edit-brand-dialog :visible.sync="addNewDialogVisible" :current-item="currentItem" :curType="1"/> -->

    <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                            <div style="height:2800px;">
                                <station @close="viewStationVisible=false" :station="station"/>
                            </div>
                    </el-dialog>

                    <el-dialog title="站点图片" v-model="viewImageVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <div>
                        <el-carousel :interval="4000" type="card" height="400px">
                            <el-carousel-item v-for="item in imageList" :key="item">
                            <!-- <h3 class="medium">{{ item }}</h3> -->
                            <el-image :src="item" >
                                <!-- :preview-src-list="imageList" -->
                                <div class="image-slot">
                                    加载中<span class="dot">...</span>
                                </div>
                            </el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="button-footer">                      
                        <span >
                            <el-button @click="viewImageVisible = false">关闭</el-button>
                        </span>                    
                    </div>
                </el-dialog>
        <el-dialog title="设置到期日期" v-model="viewSetDateVisible" width="40%" @open="open()"
                                              :modal-append-to-body='false'
                                              :close-on-click-modal='false'
                                              append-to-body>
                  <div>
                    <span class="demonstration">到期日期</span>
                    <el-date-picker
                      style="margin-left:15px;"
                      v-model="setForm.expDate"
                      align="right"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="pickerOptions">
                    </el-date-picker>         
                  </div>
                  <div class="button-footer">                      
                      <span >
                          <el-button type="primary" @click="onSaveDate()">确定</el-button>
                          <el-button @click="viewSetDateVisible = false">关闭</el-button>
                      </span>                    
                  </div>
              </el-dialog>  
  </div>
    </div>
    </div>
</template>

<script>
import CommonTable from "@/components/CommonTable";
// import {getLatestMonthDateArrayByDate} from "@/utils/time";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/auth";
// import {dealDeleteOperation} from "@/utils/tools";
import Station from '@/views/Business/Station';
import {SetExpireDate} from '@/api/operate';
import {getCurrentDate} from "@/utils/time";

export default {
  name: "Advertiser",
  components: { CommonTable,Station}, 
  computed: { ...mapGetters(["areaStationList","Media_DxList","dxTypeList"]) },
  data() {
    return {      
      props: { multiple: true },

      addNewDialogVisible: false,
      productSearchForm: {
        key: "",
        // dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
        stationArray:[],
        stationStr:'',
        typeArray:[],
      },
      currentItem: {},

      viewStationVisible:false,
            viewImageVisible:false,
            imageList:[],

      selectedMediaList:[],
      viewSetDateVisible:false,
      setForm:{
        expDate:getCurrentDate(),
      },
      pickerOptions: {
          // disabledDate(time) {
          //   return time.getTime() > Date.now();
          // },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '一个月后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 30);
              picker.$emit('pick', date);
            }
          }, {
            text: '三个月后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30*3);
              picker.$emit('pick', date);
            }
          }, {
            text: '半年后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30*6);
              picker.$emit('pick', date);
            }
          }, {
            text: '1年后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30*12);
              picker.$emit('pick', date);
            }
          }]
        },
    };
  },
  async created() {
    // await this.$store.dispatch("auditing/jclxList", {});
    await this.$store.dispatch("operate/dxTypeList");
    await this.$store.dispatch('operate/areaStationList');
    await this.onProductSearch();
  },
  methods: {   
    async onSaveDate(){
      var mids="";
      this.selectedMediaList.forEach(ele => {
        mids=mids+','+ele.mediaId;
      });
      var sDate = new Date(this.setForm.expDate).toLocaleDateString();
      await SetExpireDate({userId: getToken(),mids,date:sDate,type:1}).then(()=>{
        this.$message({message: '设置成功',type: 'success'});

        this.selectedMediaList.forEach(ele => {
          this.Media_DxList.forEach(ele2 => {
            if (ele.mediaId==ele2.mediaId){
              ele2.expireDate = sDate;
            }
          });
        });

        this.$refs.tableObj.$forceUpdate();
        this.SearchMediaList=[];
        this.$refs.tableObj.clearSelect();
        this.viewSetDateVisible=false;

      })
    },

    open() {
      console.log("do open");
      this.$nextTick(() => {
        //  执行echarts方法
      });
    },

    handleSelectionChange(val){
      this.selectedMediaList = val;			
      // console.log(val);
    } ,

    openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

    onStationXmlChange(event) {
			// console.log('onStationXmlArrayChange', this.stationArray);
      var statStr ='';
      this.productSearchForm.stationArray.forEach(element => {
        statStr=statStr+element[2]+','
      })
      this.productSearchForm.stationStr=statStr;
					// this.$emit('change', this.stationXmlArray);
		},

    async onItemEditClick(item) {
			// console.log('item',item);
			this.currentItem = item;
			this.addNewDialogVisible = true;
		},
      
    async onItemNew(){
      this.currentItem = {};
			this.addNewDialogVisible = true;
    },

    async onItemDelete(item) {
			console.log(item);
			await dealDeleteOperation({deletePromise:delBrand({userId: getToken(), brandId: item.brandId,type:'1'})})
			// await this.$store.dispatch('dataInterface/DataInterfaceList');
        await this.onProductSearch();
	},


    async onProductSearch() {
      console.log(this.productSearchForm);
      const {typeArray} = this.productSearchForm;
      var typeIds="";
      // console.log('aa',typeArray)
      if (typeArray && typeArray.length>0){
        typeIds = typeArray.join(',');
      }
      // console.log('statStr',statStr);
      
        // console.log('statStr',statStr)
      
      // console.log(sDate,eDate,this.productSearchForm.statusId);
      await this.$store.dispatch("operate/Media_DxList", {
        // sDate:sDate,
        // eDate:eDate,
        stationIds:this.productSearchForm.stationStr,
        subTypeIds:typeIds,
        key: this.productSearchForm.key,


      }).then(res=>{
          this.SearchMediaList=[];
          // console.log('this.$refs.tableObj',this.$refs.tableObj)
          this.$refs.tableObj.clearSelect();
      });

    //   setTimeout(() => {
    //         this.$nextTick(() => {
    //             this.$refs.Table.doLayout()
    //         })
    //     }, 300)

    },
  },
};
</script>

<style scoped>
.error{
    color: red;
}
.ok{
    color: green;
}
.curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
</style>